module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c2b81f3c01ee0f3e46608272777442f7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["nl","fr"],"defaultLanguage":"fr","generateDefaultLanguagePage":true,"siteUrl":"https://sob-staging.codefathers.be","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"returnObjects":true},"pages":[{"matchPath":"/:lang?/news/:slug","getLanguageFromPath":true},{"matchPath":"/:lang?/news","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-815496-1","anonymize":true},"facebookPixel":{"pixelId":"591241531495858"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
